* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

#root {
  height: 100%;
}

html {
  height: 100%;
  font-size: 62.5% !important;
}

body {
  height: 100%;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400;
  font-size: 1.6rem !important;
  line-height: 1.4285rem !important;
  color: #777;
  padding: 0rem;
  box-sizing: border-box;
}
